import React from "react"
import styled, { css } from "styled-components"

import {
  DateDisplay,
  Typography,
  Picture,
  Spacer,
  Container,
  Row,
  Col,
  media,
  HurleyThemeProps,
} from "@hurleymc/components"
import { selectFirst } from "@hurleymc/sdk/selectors"

import { Link } from "../link"
import { BlogPostHeader } from "./blog-post-header"
import { getBlogProps } from "./getBlogProps"

const FeaturedBlog = styled(BlogPostHeader)``

const BlogCardContentHeader = styled(Link)``

const BlogCardContentTitle = styled(Link)`
  h2 {
    font-size: 1rem;
  }
`

const BlogCardContentBody = styled.div`
  padding: 2rem 1rem;
`

const BlogCardContentAuthor = styled(Link)`
  display: block;
  margin-top: 1rem;
`

const BlogCardContentAuthorName = styled(Typography)`
  text-transform: none;
  font-weight: 400;
  font-size: 0.875rem;
`

const BlogCardContentPostDate = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 200;
`

const BlogCard = ({ entry }) => {
  const { image, author } = getBlogProps(entry)
  return (
    <BlogCardContent>
      <BlogCardContentHeader to={entry.uri}>
        {image.url && <Picture alt={image.title} src={image.xl || image.url} />}
      </BlogCardContentHeader>
      <BlogCardContentBody>
        <BlogCardContentTitle to={entry.uri}>
          <Typography variant="heading-2">{entry.title}</Typography>
        </BlogCardContentTitle>
        <BlogCardContentAuthor to={`/${author.uri}`}>
          <BlogCardContentAuthorName variant="heading-3" color="inherit">
            {author.fullName}
          </BlogCardContentAuthorName>
        </BlogCardContentAuthor>
        <BlogCardContentPostDate variant="heading-4" color="inherit">
          <DateDisplay date={entry.postDate} />
        </BlogCardContentPostDate>
      </BlogCardContentBody>
    </BlogCardContent>
  )
}

const BlogCardContent = styled.li`
  border: 1px solid
    ${({ theme }: HurleyThemeProps) => theme.colors.named.mercury};
  margin: 0 10px 20px;
  width: calc(100% - 20px);
  ${media.breakpoint.up(
    "md",
    css`
      width: calc(50% - 20px);
    `
  )}
  ${media.breakpoint.up(
    "lg",
    css`
      width: calc(33.3333% - 20px);
    `
  )}
`

const BlogList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 -10px;
`

const PaginationLink = styled.span`
  display: flex;
  align-items: center;
`
const PaginationStats = styled.span`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.doveGray};
`

const Pagination = styled.div`
  font-size: 0.875rem;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const BlogListing = ({ entries, category, pageInfo, totalCount }) => {
  const first = selectFirst(entries)

  return (
    <>
      <FeaturedBlog entry={first} enableLink={true} />

      <Spacer margin="2rem .5rem">
        <Container>
          <Row xsJustifyContentCenter={true}>
            <Col lg={5 / 6}>
              <BlogList>
                {entries &&
                  entries.length > 0 &&
                  entries.map((entry, idx) => {
                    if (idx === 0) {
                      return ""
                    }
                    return (
                      <BlogCard key={`blog-listing-${idx}`} entry={entry} />
                    )
                  })}
              </BlogList>
              <Pagination>
                <ul>
                  <li>
                    {pageInfo && pageInfo.hasPreviousPage && (
                      <Link
                        to={`blog/${category.slug}${
                          pageInfo.currentPage === 2
                            ? ""
                            : `/${pageInfo.currentPage - 1}`
                        }`}
                      >
                        <PaginationLink>
                          <i className="material-icons">chevron_left</i>
                          Prev
                        </PaginationLink>
                      </Link>
                    )}
                  </li>
                  <li>
                    <PaginationStats>
                      Showing {pageInfo.first}-{pageInfo.last} of {totalCount}{" "}
                      posts
                    </PaginationStats>
                  </li>
                  <li>
                    {pageInfo && pageInfo.hasNextPage && (
                      <Link
                        to={`blog/${category.slug}/${pageInfo.currentPage + 1}`}
                      >
                        <PaginationLink>
                          Next
                          <i className="material-icons">chevron_right</i>
                        </PaginationLink>
                      </Link>
                    )}
                  </li>
                </ul>
              </Pagination>
            </Col>
          </Row>
        </Container>
      </Spacer>
    </>
  )
}
