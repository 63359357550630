import React from "react"
import styled from "styled-components"

import {
  DateDisplay,
  Typography,
  AspectRatio,
  Picture,
  Spacer,
  Container,
  Row,
  Col,
} from "@hurleymc/components"
import { selectFirst } from "@hurleymc/sdk/selectors"

import { Link } from "../link"
import { DefaultProfileImage } from "../default-profile-image"
import { getBlogProps } from "./getBlogProps"

const FeaturedBlogWrapper = styled(Spacer)`
  margin: 4rem auto;
`

const AvatarWrapper = styled.div`
  height: 100px;
  width: 100px;
  margin: 0 auto;
`

const Avatar = styled.div`
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
  width: 100%;
`

const FeaturedBlogContentBody = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;
`

const PostAuthor = styled(Link)``

const PostAuthorName = styled(Typography)`
  text-transform: none;
  font-weight: 400;
  font-size: 0.875rem;
`

const PostTitleLink = styled(Link)`
  display: block;
  margin: 2rem auto;
`

const PostTitle = styled.div`
  display: block;
  margin: 2rem auto;
`

const PostDate = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 200;
`

export const BlogPostHeader = ({ entry, enableLink = false }) => {
  const { image, author } = getBlogProps(entry)
  const authorImage = selectFirst(author.contactImage)
  const wrapperStyle = image.url ? { marginTop: "-3.25rem" } : {}
  return (
    <>
      {image.url && enableLink ? (
        <Link to={entry.uri}>
          <Picture alt={image.title} src={image.xl || image.url} />
        </Link>
      ) : (
        <Picture alt={image.title} src={image.xl || image.url} />
      )}
      <FeaturedBlogWrapper style={wrapperStyle}>
        <Container>
          <Row xsJustifyContentCenter={true}>
            <Col lg={1 / 2}>
              <FeaturedBlogContentBody>
                <PostAuthor to={`/${author.uri}`}>
                  <AvatarWrapper>
                    <AspectRatio ratio="1:1">
                      <Avatar>
                        {authorImage.url ? (
                          <Picture
                            alt={authorImage.title}
                            src={authorImage.sm || authorImage.url}
                          />
                        ) : (
                          <DefaultProfileImage />
                        )}
                      </Avatar>
                    </AspectRatio>
                  </AvatarWrapper>
                  <PostAuthorName variant="heading-3" color="inherit">
                    {author.fullName}
                  </PostAuthorName>
                </PostAuthor>
                {enableLink ? (
                  <PostTitleLink to={entry.uri}>
                    <Typography variant="heading-2" color="inherit">
                      {entry.title}
                    </Typography>
                  </PostTitleLink>
                ) : (
                  <PostTitle>
                    <Typography variant="heading-2" color="inherit">
                      {entry.title}
                    </Typography>
                  </PostTitle>
                )}
                <PostDate variant="heading-4" color="inherit">
                  <DateDisplay date={entry.postDate} />
                </PostDate>
              </FeaturedBlogContentBody>
            </Col>
          </Row>
        </Container>
      </FeaturedBlogWrapper>
    </>
  )
}
